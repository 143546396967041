const isApp = async () => {
  try {
    return ['SDAndroidApp', 'SDIPhoneApp'].some((app) =>
      window.navigator.userAgent.includes(app),
    );
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default isApp;
