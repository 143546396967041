import ComplexProductCard, {
  ComplexProductCardProps,
} from '@tvg/fuse-design/complex-product-card';
import {
  useCriteoLoadEvent,
  useCriteoViewEvent,
} from 'client/hooks/criteoEvents';
import { getProductProviderProps } from 'client/utils/providerProps';
import { clearText } from 'client/utils/textModifications';

import type { ReactElement } from 'react';
import React, { useMemo, useRef } from 'react';
import {
  CIORecProduct,
  CriteoRecProduct,
  RecProduct,
} from 'server/services/recommendation';
import { RecProvider } from 'shared/consts/recommendations';
import { StyleSheetManager } from 'styled-components';

export interface InGridProductCardProps<T extends RecProvider = RecProvider>
  extends ComplexProductCardProps {
  provider: T;
  product: RecProduct &
    (T extends RecProvider.Constructor
      ? CIORecProduct
      : T extends RecProvider.Criteo
      ? CriteoRecProduct
      : RecProduct);
  onLoadBeacon?: string;
}

function InGridProductCard<T extends RecProvider>({
  provider,
  product,
  onLoadBeacon,
  ...props
}: InGridProductCardProps<T>): ReactElement {
  const container = useRef<HTMLDivElement | null>(null);
  if (onLoadBeacon) {
    useCriteoLoadEvent(onLoadBeacon);
  }
  useCriteoLoadEvent(product.onLoadBeacon);
  useCriteoViewEvent(container);

  const titleMemo = useMemo(
    () => clearText(product?.title ?? ''),
    [product?.title],
  );

  const brandMemo = useMemo(
    () => clearText(product?.brand ?? ''),
    [product?.brand],
  );

  const imageMemo = useMemo(
    () => ({
      url: product?.image?.url,
      altText: clearText(`Image of ${product?.brand ?? ''} ${product?.title}`),
    }),
    [product?.image?.url, product?.brand, product?.title],
  );

  const styleTarget = document.createElement('div');
  styleTarget.setAttribute('id', 'recommendations-inGrid-styles');
  document.head.appendChild(styleTarget);

  return (
    <div ref={container}>
      <StyleSheetManager target={styleTarget}>
        <ComplexProductCard
          brand={brandMemo}
          title={titleMemo}
          image={imageMemo}
          productUrl={product.url}
          price={{
            current: product.price.current,
            previous: product.price.previous,
            showSave: false,
          }}
          {...props}
          {...getProductProviderProps({ provider, product }, props)}
          promotionIdentifier={{
            displayName: 'Featured',
            colour: 'featured',
          }}
          data-criteo-view-beacon={product.onViewBeacon}
        />
      </StyleSheetManager>
    </div>
  );
}

export default InGridProductCard;
